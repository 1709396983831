const ProductsActionTypes = {
  GET_PRODUCTS_BY_LOCATION: "GET_PRODUCTS_BY_LOCATION",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  ADD_PRODUCT: "ADD_PRODUCT",
  TOGGLE_MODAL: "TOGGLE_MODAL",
  SET_PRODUCT_TO_EDIT: "SET_PRODUCT_TO_EDIT",
};

export default ProductsActionTypes;
