export const placeLocation = "20hvin";
export let serverURI = "https://a-20h20-server-v2-6f2c2d3816e2.herokuapp.com";

export const colors = {
  main: "white", //white
  secondary: "#777758", //vert foncé
  tertiary: "#62714e", // vert clair
  ecriture: "#000000", //black
  background: "#f4ba9a",
  background2: "#ff8080",
  gold: "#7B7338",
};
// pour le style2 et non pas le style ardoise
export const categoriesStyle2 = [];

// pour les separations entre les articles
export const allowedCategories = [];

export const phoneNumber = "+33495722052";

export const facebook = "https://www.facebook.com/20HeuresVin";
export const instagram = "https://www.instagram.com/le_20_heures_vin";
export const url = "https://20h20.fr";
export const mail = "christophe.torre75@gmail.com";

export const GOOGLE_API_KEY = "AIzaSyCXqBKgfwkkW1urDlW0_GBNaS79P1ZjU0E";
